import React from 'react';
import { useState, useEffect, useContext } from 'react';
import Dialog from '../Dialog';
import { Box, IconButton } from '@material-ui/core';
import axios from '../../../common/AxiosConfig';
import { AppContext } from '../../../../RoutesWithAuth';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getFormatedDate } from '../../../common/commons';
import Toolbar from '../grid/Toolbar';

const AuditTrail = ({
    hideComponent,
    dsarRequestId
}) => {

    const [auditTrailData, setAuditTrailData] = useState();
    const context = useContext(AppContext)
    const authToken = context.authToken.get


    useEffect(() => {
        axios.get(`ticket/audittrail/${dsarRequestId}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setAuditTrailData(res.data?.map((_displayAuditTrailData,index)=>{
                return {
                    rowId: index + 1,
                    created_date_UTC : getFormatedDate(_displayAuditTrailData.created_date, true),
                    created_date_Local : getFormatedDate(_displayAuditTrailData.created_date),
                    ..._displayAuditTrailData
                }
            }))
        }).catch(err => {
            console.log(err)
        })
    }, [dsarRequestId])

    const downloadAuditLogs = () => {
        let csv = "#,Activity,Details,User,Date Time"
        csv = auditTrailData.reduce((total, {
            rowId,
            activity,
            additional_data,
            user_name,
            created_date_UTC
        }) => total + `\n${rowId},${activity},${additional_data === null ? '' : 
            additional_data.replace(/[\n,]/g, ' ')},${user_name},${created_date_UTC.replace(/[\n,]/g, ' ')}`, csv);
		const link = document.createElement('a');
        const file = new Blob(["\ufeff", csv])
        link.href = URL.createObjectURL(file);
        link.setAttribute('download', `Audit_Trail_${dsarRequestId}.csv`);
		document.body.appendChild(link);
		link.click();
		link.remove()
	}
    return (
        <Dialog
            isOpen={true}
            isLoading={!auditTrailData}
            updateDialogStatus={() => {
                hideComponent()
            }}
            popupGridData={auditTrailData || []}
            popupColumns={[{
                name: "#",
                mapping: "rowId",
                width: 50
            }, {
                name: 'Activity',
                mapping: 'activity',
                displayTootip: ["activity"]
            }, {
                name: "Details",
                mapping: 'additional_data',
                displayTootip: ["additional_data"]
            }, {
                name: "User",
                mapping: 'user_name',

            }, {
                name: "Date Time",
                mapping: 'created_date_UTC',
                displayTootip : ["created_date_Local"]
            }]}
            enablePagination={false}
            title={(
                <Box paddingBottom={"5px"} fontSize={"1rem"}>
                    <div className='modelheader'>Audit Trail</div>
                    <Toolbar 
                        downloadLabel={"Download"}
                        handleDownloadGrid={downloadAuditLogs}
                    />
                </Box>
            )}
        />
    )
}
export default AuditTrail;