import React, { useState,useContext, useEffect} from 'react';
import { AppContext } from '../../../../../RoutesWithAuth';
import axios from '../../../../common/AxiosConfig';
import DataGrid from '../../grid';
import { makeStyles } from "@material-ui/core/styles";
import { GridEditInputCell } from '../../grid/EditInputCell';
import RowMenuCell from '../../grid/RowEditActions';

const emailValidation = (email)=>{
    // eslint-disable-next-line no-useless-escape
    return email ? /^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+)(,\s*\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+)*$/.test(email) : true;
}
const customValidation=(row)=>{
    // Adding the Custom Email Validation to verify the User Entered Email
    return emailValidation(row?.team_email?.value);
}
const COLUMNS = [{
    name : "#",
    mapping:"rowId",
    width : 40,
},{
    name : "Application Name",
    mapping:"app_name",
    displayTootip : ["app_name"],
    width : 190,
    type: 'singleSelect',
    customSingleSelectFilter: true
},
{
    name : "Primary Contact Name",
    mapping:"primary_contact_name",
    editable : true,
    width : 180,
    renderEditCell : (params)=>{
        return <GridEditInputCell {...params} />
    }
},{
    name : "Primary Contact Email",
    mapping:"primary_contact_email",
    editable : true,
    displayTootip : ["primary_contact_email"],
    width : 170,
    renderEditCell : (params)=>{
        return <GridEditInputCell {...params} />
    }
},{
    name : "Primary Contact Phone",
    mapping:"primary_contact_phone",
    editable : true,
    width : 180,
    renderEditCell : (params)=>{
        return <GridEditInputCell {...params} />
    }
},{
    name : "Escalation Contact Name",
    mapping:"escalation_contact_name",
    editable : true,
    width : 180,
    renderEditCell : (params)=>{
        return <GridEditInputCell {...params} />
    }
},{
    name : "Escalation Contact Email",
    mapping:"escalation_contact_email",
    displayTootip : ["escalation_contact_email"],
    editable : true,
    width : 190,
    renderEditCell : (params)=>{
        return <GridEditInputCell {...params} />
    }
},{
    name : "Escalation Contact Phone",
    mapping:"escalation_contact_phone",
    editable : true,
    width : 180,
    renderEditCell : (params)=>{
        return <GridEditInputCell {...params} />
    }
},
{
    name : "Team Email",
    mapping:"team_email",
    editable : true,
    displayTootip : ["team_email"],
    width : 170,
    renderEditCell : (params)=>{
        return <GridEditInputCell {...params} />
    }
},{
    name : "Edit",
    mapping:"actions",
    width : 60,
    adminEnabled : true,
    editable : false,
    renderCell : (params)=>{
        return <RowMenuCell {...params} customValidation={customValidation} validationColumns={[
                    "escalation_contact_email",
                    "primary_contact_name",
                    "primary_contact_email",
                    "primary_contact_phone",
                    "escalation_contact_name",
                    "escalation_contact_phone"
        ]} />
    }
}];

const useStyles = makeStyles(() => ({
    contactsWrapper:{
        marginTop : '4px',
        marginRight: '15px',
        height : 'calc( 100% - 100px )'
    }
}));
function Contacts(props) {
    
    const [appsData,setAppsData] = useState([]);
    const [editRowsModel,setEditRowsModel] = useState({});

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const apps = context.apps.get

    const classes = useStyles()
    useEffect(() => {
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    const fetchData = () => {
        axios.get(`contacts`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setAppsData(res.data)
            
        }).catch(err => {
            console.log(err)
        })
    }
    const updateApp = (rowInfo) => {

        axios.put(`/contacts/${rowInfo.app_name}`, {
            primary_name: rowInfo.primary_contact_name.trim(),
            primary_email: rowInfo.primary_contact_email.trim(),
            primary_phone: rowInfo.primary_contact_phone.trim(),
            escalation_name: rowInfo.escalation_contact_name.trim(),
            escalation_email: rowInfo.escalation_contact_email.trim(),
            escalation_phone: rowInfo.escalation_contact_phone.trim(),
            escalation_team_email : rowInfo.team_email?.trim()
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            fetchData();
        }).catch(err => {
            props.toggleError(err.response.data.message);
        })
    }
    
    const dataGridColumns = ()=>{
        return COLUMNS.map((column)=>{
            let disableColumnMenu = true;
            let valueOptions;
            if(column.mapping === "app_name"){
                disableColumnMenu = false;
                valueOptions = appsData?.map(({ app_name }) => app_name);
            }
            return {
                ...column,
                disableColumnMenu,
                valueOptions
            }
        });
    } 
    
    const appsGridData = ()=>{
        
        return appsData?.map((row, i)=>{
            return {
                rowId : (i + 1),
                id : row.app_name,
                app_name : row.app_name,
                primary_contact_name : row.data.primary.name,
                primary_contact_phone : row.data.primary.phone,
                primary_contact_email : row.data.primary.email,
                escalation_contact_name : row.data.escalation.name,
                escalation_contact_phone : row.data.escalation.phone,
                escalation_contact_email : row.data.escalation.email,
                team_email : row.data.escalation.team_email
            }
        })
    }
    const handleRowsModelChange = React.useCallback((newModel)=>{
        const updatedModel = {...newModel}
        setEditRowsModel(updatedModel)
    },[]);

    return (
        <div className={classes.contactsWrapper}>
            <DataGrid 
                disableSelectionOnClick={false}
                enableCustomFiltering={true}
                columns={dataGridColumns()}
                customHeight={"100%"}
                rows={appsGridData()}
                editRowsModel={editRowsModel}
                onEditRowsModelChange={handleRowsModelChange}
                onRowEditCommit={(rows,event)=>{
                    if(event.rowInfo){
                        updateApp(event.rowInfo);
                    }
                    if(event.invalidRowInfo){
                        let errorMsg = "All fields are required!";
                        if(!emailValidation(event.invalidRowInfo.team_email)){
                            errorMsg = "Team Email is Invalid"
                        }
                        props.toggleError(errorMsg);
                    }
                }}
            />
        </div>
    )
}
export default Contacts;