import { createTheme } from "@material-ui/core";

export default createTheme({
    overrides :{
        MuiInputBase :{
            root : {
                fontSize: '0.65rem',
            },
            input :{
                padding : '6px 10px 7px'
            }
        },
        MuiMenuItem :{
            root : {
                fontSize: '0.8rem',
                padding: '0px 0px 0px 10px',
            },
        },
        MuiIconButton :{
            root : {
                color : '#267db7',
                outline : '0px !important'
            }
        },
        MuiTab :{
            textColorRegular :{
                opacity : 0.7
            },
            textColorInherit:{
                opacity : 1
            }
        },
        MuiSvgIcon :{
            fontSizeSmall :{
                fontSize: '1.05rem'
            },
            colorError :{
                color : "#eb0a1e"
            }
        }
    }
});