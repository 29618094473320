/**
 * FormGroup Component is a custom component which renders input fields based on the JSON.
 * 
 */

import { FormControl, Grid, TextField, Select, MenuItem, Button, Checkbox, FormControlLabel, Box } from '@material-ui/core';
import React, { useState, useImperativeHandle } from 'react';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    root: {
        '& .ml-1': {
            fontWeight: 'bold',
            width: '100%'
        },
        '& .MuiInputBase-input': {
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '0.8rem',
            paddingLeft: '10px'
        },
        '& .MuiListItem-root': {
            fontSize: '0.8rem',
            padding: '0px'
        }
    },
    menuitemmultiselect: {
        fontSize: '0.8rem',
        padding: '0px',
        '& .Mui-checked': {
            color: '#7f9ed7'
        }
    },
    menuitemmultiselectNoCheck: {
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px'
    },
    disabledMenuItem: {
        pointerEvents: 'none',
    },
    actionButton: {
        fontSize: '0.75rem',
        padding: '4px',
        backgroundColor: '#7f9ed7',
        margin: '0px 10px 0px'
    },
    formActionWrapper: {
        marginBottom: '0px'
    }
});


function FormGroup({
    fields,
    disableFormActions = false,
    customFormUpdates,
    refreshGridWithSearch,
    formWrapperClassName,
    formError = "",
    handleClearForm,
    defaultFormData
}, ref) {
    // useImperativeHandle - This is used to allow access to internal Functionality usiing the reference
    useImperativeHandle(ref, () => {
        // Exposing only the Refresh Form so that user can reset By calling this method
        return {
            resetFormToDefaults,
            handleFormDataState
        }
    });

    const getDefaultValues = (defaultFormData = {}) => {
        const defaultValues = defaultFormData;
        fields.map((field) => {
            let defaultValue = field.defaultOption;
            if (field.isMulti && field.defaultOption) {
                defaultValue = [field.defaultOption];
            }
            defaultValues[field.name] = defaultValues[field.name] || defaultValue;
            return field;
        })
        return defaultValues;
    }

    const defaultValues = getDefaultValues(defaultFormData);
    const classes = useStyles();
    const [formData, setFormData] = useState(defaultValues);

    const resetFormToDefaults = () => {
        handleClearForm && handleClearForm();
        setFormData({
            ...defaultValues
        });
    }
    const handleFormDataState = () => {
        setFormData(getDefaultValues());
    }
    const handleSubmitForm = () => {
        refreshGridWithSearch(formData);
    }
    const updateFormData = (field, value) => {
        if (field.isMulti) {
            const existingselection = formData[field.name] || [];
            const newSelection = value.filter(v => !existingselection.includes(v));
            if (newSelection.includes("All")) {
                value = ["All"]
            }

        }
        const updatedFormData = {
            ...formData,
            [field.name]: value
        };
        setFormData(updatedFormData);
        if (disableFormActions) {
            customFormUpdates(updatedFormData);
        }
    }
    const isDisabled = (name, fieldObj) => {
        if (!fieldObj.isMulti) {
            return false;
        }
        const isAllSelected = formData[fieldObj.name]?.includes("All");
        return isAllSelected && name !== "All";
    }
    const renderCheckBox = (field) => {
        return (
            <Grid item xs={3} key={'CheckBoxField' + field.name} className={`${field.customInputCls}`}>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData[field.name]}
                                onChange={(event) => { updateFormData(field, event.target.checked) }}
                                name={field.name}
                                color="primary"
                            />
                        }
                        label={field.label}
                    />
                </div>
            </Grid>
        )
    }

    return (
        <Grid container className={`${classes.root} ${formWrapperClassName}`}>
            {fields.map((field) => {
                if (field.type === "text" || field.type === "number") {

                    const additionalProps = field.additionalProps || {};
                    if (field.handleEnterToSubmit) {
                        additionalProps["onKeyDown"] = (e) => {
                            if (e?.key === "Enter") {
                                handleSubmitForm();
                            }
                        }
                    }
                    return (
                        <Grid item xs={field.size || 3} key={"textField" + field.name}>
                            <div className="form-group col">
                                <label for={field.name} className="ml-1">{field.label}</label>
                                <TextField
                                    size={"small"}
                                    data-testid={"textField-" + field.name}
                                    type={field.type}
                                    onChange={(event) => { updateFormData(field, event.target.value) }}
                                    value={(formData[field.name] || [field.defaultOption])}
                                    fullWidth
                                    id={field.name}
                                    label=""
                                    variant="outlined"
                                    {...additionalProps}
                                />
                            </div>
                        </Grid>
                    )
                } else if (field.type === "multiselect" && field.hidden !== true) {
                    return (
                        <Grid item xs={field.size || 3} key={"multiselect-" + field.label} >
                            <div className="form-group col">
                                {field.label && (<label for={field.name} className="ml-1">{field.label}</label>)}
                                <FormControl fullWidth className={`${field.customInputCls}`} variant="outlined" >
                                    <Select
                                        size={"small"}
                                        id={field.name}
                                        multiple={field.isMulti}
                                        onChange={(event) => { updateFormData(field, event.target.value) }}
                                        value={function () { return formData[field.name] || (field.displayOption ? field.defaultOption : [field.defaultOption]) }()}
                                        renderValue={(selected) => {
                                            if (field.displayOption) {
                                                let displaySelectedValue = '';
                                                field.options.forEach((_option) => {
                                                    if (_option.id === selected) {
                                                        displaySelectedValue = _option[field.displayOption]
                                                    }
                                                })
                                                return displaySelectedValue;
                                            }
                                            return field.isMulti ? selected?.join?.(', ') : selected
                                        }}
                                    >
                                        {field.displayOption ? (field.options.map((_option) => (
                                            <MenuItem key={_option.id} className={`${classes.menuitemmultiselect} ${field.removeCheckBox && classes.menuitemmultiselectNoCheck} ${(isDisabled(_option.id, field) && classes.disabledMenuItem)}`} value={_option.id}>
                                                {!field.removeCheckBox && <Checkbox disabled={isDisabled(_option.id, field)} checked={formData[field.name]?.indexOf(_option.id) > -1} />}
                                                {_option[field.displayOption]}
                                            </MenuItem>
                                        ))) :
                                            (field.options.map((name) => (
                                                <MenuItem key={name} className={`${classes.menuitemmultiselect} ${field.removeCheckBox && classes.menuitemmultiselectNoCheck} ${(isDisabled(name, field) && classes.disabledMenuItem)}`} value={name}>
                                                    {!field.removeCheckBox && <Checkbox disabled={isDisabled(name, field)} checked={formData[field.name]?.indexOf(name) > -1} />}
                                                    {name}
                                                </MenuItem>
                                            )))}
                                    </Select>
                                </FormControl>
                                {field.isMulti && formData[field.name]?.length > 1 && (<p className="multiSelectedOptions"> {formData[field.name].join(' , ')}</p>)}
                            </div>
                        </Grid>
                    )
                } else if ((field.type === "date" || field.type === "month") && !field.isHidden) {
                    return (
                        <Grid item xs={3} key={'dateField' + field.name}>
                            <div className="form-group col">
                                <label for={field.name} className="ml-1">{field.label}</label>
                                <TextField variant="outlined"
                                    disabled={field.isDisabled}
                                    id={field.name}
                                    inputProps={{
                                        size: "small",
                                        ...field?.inputAdditionalProps
                                    }}
                                    label=""
                                    type={field.type}
                                    onChange={(event) => { updateFormData(field, event.target.value) }}
                                    value={(formData[field.name] || [field.defaultOption])}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </Grid>
                    )
                } else if (field.type === "checkbox") {
                    return renderCheckBox(field)
                }
                return null;
            })}
            {formError !== "" && (<Box color={"red"}>
                {formError}
            </Box>)}
            {!disableFormActions && <Grid container className={classes.formActionWrapper} spacing={4} xs={12} justifyContent={"center"}>
                <Grid item >
                    <Button className={classes.actionButton} onClick={resetFormToDefaults} variant="contained" color="primary">
                        Cancel
                    </Button>
                </Grid>
                <Grid item >
                    <Button className={classes.actionButton} onClick={handleSubmitForm} variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
            </Grid>}
        </Grid>);
}
// Wrapping component with forwarRef so that reference functionality works
export default React.forwardRef(FormGroup);