import React from 'react';
import DataGrid from '../../grid';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Snackbar, TextField, Tooltip, makeStyles } from '@material-ui/core';
import { FindInPage } from '@material-ui/icons';
import axios from '../../../../common/AxiosConfig';
import { AppContext } from '../../../../../RoutesWithAuth';
import { useContext } from 'react';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import CustomDialog from '../../Dialog';
import { LoadingIndicator } from '../../reports/reconcilationreport/MuiStyled';
import { RESPONSE_ACTION_MAPPING } from './constants';
import { DialogTitle } from '../../reports/ReportCommonPopup';
import { useRef } from 'react';



const generateJiraBaseLink = (name, ticket_id) => {
    const toyotaconnectedNames = ["Servco", "Qualtrics", "DriverWaiver", "HireVue", "ITGFLEET", "WeaveGrid"]
    const tmnaservicenow = [
        'Service Connect',
        'Customer Care-AMS',
        'CT-BI',
        'Customer Care-Sustainment',
    ]
    if (name === "Cerence") {
        return `https://tgt.assist.com/portal/incidents/${ticket_id}`;
    } else if (toyotaconnectedNames.includes(name)) {
        return `https://toyotaconnected.atlassian.net/browse/${ticket_id}`
    } else if (tmnaservicenow.includes(name)) {
        return `https://tmna.service-now.com/sc_req_item_list.do?sysparm_first_row=1&sysparm_query=GOTOnumber%3d${ticket_id}`
    }
    return null;
}



const useStyles = makeStyles(() => ({
    dialog: {
        fontSize: '0.75rem',
        '& .MuiDialogTitle-root': {
            padding: '10px 10px',
            background: '#7395d3',
            color: 'white',
            '& .MuiButtonBase-root': {
                padding: '0px',
                color: 'white'
            }
        },
        '& .isInvalidText': {
            marginTop: '5px',
            textAlign: 'center',
            color: 'red'
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'space-evenly',
            '& .MuiButtonBase-root': {
                background: '#4472c4',
                paddingTop: '0px',
                paddingBottom: '0px',
                color: "white",
                textTransform: "none"
            }
        }
    },
}));

const List = ({
    data,
    requestDetails, handleViewResponseDownstream,
    reloadInformation
}) => {

    const context = useContext(AppContext)
    const authToken = context.authToken.get;
    const classes = useStyles();
    const ticketIdRefInput = useRef();


    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // displayHubStatusPopup state is used to store popup visibility state
    // true represents Popup to be visible ,false - represents popup should be hidden
    const [displayDialog, setDisplayDialog] = useState(false);

    // hubStatusData state is used to store hub status API response.
    // null represents Loading , [] - represents Empty response
    const [hubStatusData, setHubStatusData] = useState(null);

    const [createStatusData, setCreateStatusData] = useState(null);
    const [createInvalidMsg, setCreateInvalidMsg] = useState(null);

    const handleCreate = (rowInfo) => {
        setCreateStatusData(rowInfo);
    }

    const handleCreateStatusDialogClose = () => {
        setCreateStatusData(null);
        setCreateInvalidMsg(null);
    }
    const handleCreateStatusDialogSubmit = () => {
        let selectedValue = ticketIdRefInput.current.querySelector("input").value;
        if (!selectedValue) {
            setCreateInvalidMsg("Ticket ID is mandatory");
        } else {
            setCreateInvalidMsg(null);
            setIsLoading(true);
            setCreateStatusData(null);
            axios.post('app_tickets/mark_created', {
                dsar_id: requestDetails.dsar_id,
                app_id: createStatusData.id,
                system_generated_ticket_id: selectedValue,
            }, {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {

                setIsLoading(false);
                reloadInformation();

            }).catch(() => {
                setIsLoading(false);
            })
        }
    }

    const handleReCreate = (rowInfo) => {
        setIsLoading(true)
        axios.post('/downstream/resend_message', {
            dsar_id: requestDetails.dsar_id,
            app_name: rowInfo["name"],
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setIsLoading(false)
            setMessage({
                severity: "success",
                text: res.data.message
            })
        }).catch(err => {
            setIsLoading(false)
            if (err.response.data.message) {
                setMessage({
                    severity: "error",
                    text: err.response.data.message
                })
            }
        })
    }
    /**
     * triggerHubStatus Function will be called when user clicks on the Hub Icon.
     */
    const triggerHubStatus = () => {
        // Display the Hub Status Dialog
        setDisplayDialog(true);
        // Mark the loading State null represents loading is true
        setHubStatusData(null);
        axios.get(`tc/tc_hub_status?dsar_id=${requestDetails.dsar_id}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            let tc_hub_response = res?.data || [];
            // when there is no data available
            if (res.status === 204) {
                tc_hub_response = [];
            }
            // Sorting the Response based on the Product Name
            tc_hub_response = tc_hub_response.sort((a, b) => {
                if (a.product < b.product) {
                    return -1;
                } else if (a.product > b.product) {
                    return 1;
                }
                return 0;
            });
            setHubStatusData(tc_hub_response);
        }).catch(err => {
            console.log(err)
            setHubStatusData([]);
        })

    }

    const columns = [{
        name: "Application Name",
        mapping: "display",
        flex: 1,
        renderCell: (params) => {

            const app_name = params.row["name"];
            if (app_name === "TC") {
                return (
                    <>
                        {params.row["display"]}
                        <IconButton
                            style={{
                                marginLeft: "10px"
                            }}
                            onClick={triggerHubStatus}
                        >
                            <img src="/TCHubStatus.png" height="20" alt="TCHubStatus" />
                        </IconButton>
                    </>
                )
            }
            return params.row["display"]

        }
    },
    {
        name: "Response Action",
        mapping: "response_action",
        width: 160,
        renderCell: (params) => {

            const expected_attachments = params.row["expected_attachments"];
            const existing_attachments = params.row["actual_attachments"];
            const response_action = params.row["response_action"];
            return (
                <>
                    {RESPONSE_ACTION_MAPPING[response_action]}
                    {(expected_attachments - existing_attachments > 0) && (
                        <Tooltip title={`Pending ${expected_attachments - existing_attachments} attachment(s)`}>
                            <Box marginLeft={"10px"}>
                                <img src="/yellow_missing.png" width="20" alt="pending" />
                            </Box>
                        </Tooltip>
                    )}
                </>
            )
        }
    },
    {
        name: "Ticket Created On",
        mapping: "created_date_utc",
        displayTootip: ["created_date_local"],
        width: 160
    }, {
        name: "Response Received On ",
        mapping: "response_created_date_utc",
        displayTootip: ["response_created_date_local"],
    }, {
        name: "Request Automated",
        mapping: "is_request_automated"
    }, {
        name: "JIRA ID",
        mapping: "system_generated_ticket_id",
        renderCell: (params) => {
            const name = params.row["name"];
            const jiraId = params.row["system_generated_ticket_id"];
            if (jiraId) {
                const jiraUrl = generateJiraBaseLink(name, jiraId);
                if (jiraUrl) {
                    return (<a rel="noopener noreferrer" href={jiraUrl} target="_blank">
                        {jiraId}
                    </a>)
                }
                return <>

                </>
            }
            return <></>
        }
    }, {
        name: "Create/ Recreate Ticket",
        mapping: "actions",
        align: 'center',
        renderCell: (params) => {
            const is_request_automated = params.row["is_request_automated"];
            const create_ticket_status = params.row["create_ticket_status"];
            const response_is_open = params.row["response_is_open"];
            const integration_pattern = params.row["integration_pattern"];

            if (is_request_automated === 'No' && !create_ticket_status) {
                return (<Button
                    variant="contained"
                    color="primary"
                    className={'actionButton'}
                    onClick={() => {
                        handleCreate(params.row);
                    }}
                >
                    Create
                </Button>)
            }
            if (response_is_open === true
                && integration_pattern === "API Push"
                && is_request_automated === "Yes") {
                return (<Button
                    variant="contained"
                    color="primary"
                    className={'actionButton'}
                    onClick={() => {
                        handleReCreate(params.row);
                    }}
                >
                    Recreate
                </Button>)
            }

            return <></>

        }

    }, {
        name: "View Response",
        mapping: "viewResponse",
        align: 'center',
        // headerAlign: 'left',
        renderCell: (params) => {
            const display = params.row["display"];
            const app_id = params.row["id"];

            return (<IconButton
                onClick={() => {
                    handleViewResponseDownstream({
                        tabname: `${display} Response`,
                        app_id: app_id
                    })
                }}
            >
                <FindInPage />
            </IconButton>)
        }
    }];

    const handleClose = () => {
        setMessage(null);
    }
    return (
        <Box height={"calc( 100% - 130px )"}>
            {createStatusData && (
                <Dialog maxWidth={"sm"} open={true} className={classes.dialog}>
                    <DialogTitle onClose={handleCreateStatusDialogClose} >
                        Create Ticket
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box minWidth={350}>
                            <Box>
                                <Box component={"span"} fontWeight={"bold"} marginRight={"10px"} >
                                    Application Name :
                                </Box>
                                {createStatusData?.display}
                            </Box>
                            <Box display={"inline-flex"} marginTop={"10px"} alignItems={"center"}>
                                <Box marginRight={"10px"} fontWeight={"bold"}>
                                    Ticket Id :
                                </Box>
                                <Box>
                                    <TextField
                                        size={"small"}
                                        type={"text"}
                                        fullWidth
                                        label=""
                                        ref={ticketIdRefInput}
                                        variant="outlined"
                                        placeholder='Enter the Ticket ID'
                                    />

                                </Box>
                            </Box>
                            <Box className='isInvalidText'>
                                {createInvalidMsg}
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCreateStatusDialogClose} >
                            Cancel
                        </Button>
                        <Button onClick={handleCreateStatusDialogSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Snackbar
                style={{
                    marginTop: "30px"
                }}
                open={!!message}
                onClose={handleClose}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

            >
                <Alert
                    onClose={handleClose}
                    severity={message?.severity}>
                    {message?.text}
                </Alert>
            </Snackbar>
            {isLoading && <LoadingIndicator />}
            <DataGrid
                columns={columns}
                themeColors={{
                    headerColumnColor: '#dae3f3',
                    headerColumnfontColor: 'black',
                    rowbackground: '#f2f2f2 !important',
                    cellBorder: '1px solid white',
                    rowOddBackground: '#f8f9fa !important'
                }}
                getRowClassName={(params) => {
                    const _classes = [];
                    // For Displaying the Custom Class for the Error Row
                    if (params?.row?.response_action === "error") {
                        _classes.push("isInactive-row-cust-cls");
                    }
                    return _classes.join(" ");
                }}
                rows={data || []}
                customHeight={"100%"}
            />
            {displayDialog && (
                <Box>
                    <CustomDialog
                        isOpen={!!displayDialog}
                        isLoading={!hubStatusData}
                        updateDialogStatus={() => {
                            setDisplayDialog(null)
                        }}
                        popupGridData={hubStatusData?.map((_displayHubStatusPopup, index) => {
                            // Formating the response to add the ID which is required for the grid for displaying row index
                            return {
                                id: index + 1,
                                ..._displayHubStatusPopup
                            }
                        }) || []}
                        popupColumns={[{
                            name: "#",
                            mapping: "id",
                        }, {
                            name: 'TC System Name',
                            mapping: 'product'
                        }, {
                            name: 'Response Status',
                            mapping: 'status'
                        }]}
                        enablePagination={false}
                        title={(
                            <>
                                <span>RequestId</span> : {requestDetails?.dsar_id}
                            </>
                        )}
                    />
                </Box>
            )}
        </Box>
    )
}

export default React.memo(List);