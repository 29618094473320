import React from 'react';
import { ResponsiveLine } from '@nivo/line';


const assignColor = (d, toFilter) => {
    const colors = {
        "CTP": "#2f4f4f",
        "CT Marketing Portal": "#a9b14e",
        "CTP 17+": "#f53d3d",
        "CTP 21MM-ADR": "#ffc107",
        "CTP 21MM-AUDIO": "#FF9000",
        "CTP 21MM-IP Apps": "#e83e8c",
        "CTP 21MM-UP": "#007bff",
        "CTP MP": "#007EB9",
        "Public Charging": "#81c36d",
        "Cerence": "#7f0000",
        "Compliance Platform":'#8d4573',
        "Customer Care-AMS": "#008000",
        "CT BI": "#00008b",
        "Customer Care-Sustainment": "#ff8c00",
        "Digital Key":"#6134CD",
        "DK Gen1": "#095019",
        "DK Gen2": "#9b5169",
        "Driver Waiver":"#4d34e2",
        "GAP": "#a88d32",
        "HireVue": "#aba1e5",
        "Hybrid Pulse": "#abf2e2",
        "ITG Fleet": "#1e9cb0",
        "Mobility": "#85723c",
        "NSight": "#359b82",
        "Payment Platform": "#043c74",
        "Qualtrics": "#63c2b1",
        "Remote Services": "#bde7c0",
        "Subaru": "#f40606",
        "SXM": "#4b0082",
        "SXM Audio": "#00ff00",
        "Servco": "#00ffff",
        "Service Connect": "#ff00ff",
        "Stencil": "#b9ef19",
        "SOA": "#ffee03",
        "TC": "#ffdead",
        "UDAP": "#20c997",
        "VGI": "#daf7a6",
        "WeaveGrid": "#9c85b1",
        "Woven Core": "#e83e8c",
        "Xevo": "#ff69b4",
        "Zuora": "#1e90ff",

        "Right-to-Correct": "#ffce00",
        "Right-to-Know-Detailed": "#00ff00",
        "Right-to-Delete": "#ff0000",
        "Right-to-OptOut": "#0000ff",
        "All": "#535353",
    }

    if (toFilter.includes(d.id)) {
        return "#FFFFFF"
    } else {
        return colors[d.id]
    }
}

const getTooltip = (d) => {
    return (
        <div
			style={{
				background: "white",
				padding: "9px 12px",
				border: "1px solid #ccc",
				borderRadius: "10px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				color: "black",
			}}
		>
            {`${d.point.serieId} (${d.point.data.x}): ${d.point.data.y} days`}
        </div>
    )
}

const ProcessingTimeChart = ({ data, toFilter, setToFilter }) => (
    <ResponsiveLine
        data={data}
        tooltip={getTooltip}
        margin={{ top: 50, right: 180, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Month',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Processing Time (days)',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        colors={(d) => assignColor(d, toFilter)}
        pointSize={10}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                onClick: (d) => {
                    if (toFilter.includes(d.id)) {
                        setToFilter(toFilter.filter(el => el !== d.id))
                    } else {
                        setToFilter([d.id].concat(toFilter))
                    }
                },
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    },
                ]
            }
        ]}
    />
)

export default ProcessingTimeChart;
