import React from 'react';
import DataGrid from './DataGrid';
import { makeStyles } from "@material-ui/core/styles";

const colortheme = {
  headerColumnColor: '#7f9ed7',
  headerColumnfontColor: 'white',
  rowbackground: '#e9ebf5 !important',
  cellBorder: '1px solid white',
  rowOddBackground: '#cfd5ea !important'
}
const darktheme = {
  headerColumnColor: '#afabab',
  headerColumnfontColor: 'black',
  rowbackground: '#f2f2f2 !important',
  cellBorder: '1px solid #d0d0d1',
  rowOddBackground: '#f8f9fa !important'
}

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-root .MuiDataGrid-menuIcon': {
      visibility: 'visible'
    },
    '& .MuiDataGrid-editInputCell': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '5px',
      fontSize: '0.8rem'
    },
    '& .Mui-error': {
      backgroundColor: 'rgb(126,10,15,0.1)',
      color: '#750f0f'
    },
    '& .MuiDataGrid-renderingZone div.MuiDataGrid-row.Mui-selected': {
      backgroundColor: '#dedede !important',
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-renderingZone div.MuiDataGrid-row:nth-of-type(odd).Mui-selected': {
      backgroundColor: '#bec8e8 !important',
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-root': {
      fontFamily: 'inherit',
      color: 'rgba(0, 0, 0, 1)',
    },
    '& div.MuiDataGrid-columnsContainer': {
      backgroundColor: 'rgb(241 241 241)'// Header Scroll Color
    },
    '& .super-app-theme--header': {
      backgroundColor: (props) => props.headerColumnColor,
      color: (props) => props.headerColumnfontColor,
      fontSize: '0.75rem',
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
      }
    },
    '& div.MuiDataGrid-row': {
      backgroundColor: (props) => props.rowbackground,
      fontSize: '0.8rem',
      '& div.MuiDataGrid-cell': {
        borderBottom: (props) => props.cellBorder,
        borderRight: (props) => props.cellBorder,
      },
      '& span.MuiDataGrid-cell': {
        borderBottom: '0px'
      }
    },
    '& div.MuiDataGrid-row:nth-of-type(odd)': {
      backgroundColor: (props) => props.rowOddBackground,
      fontSize: '0.8rem',
    },
  },
});

function Grid(props) {

  const {
    rows,
    columns,
    disableSelectionOnClick = true,
    enableCustomFiltering = false,
    enabledarktheme = false,
    themeColors
  } = props;

  const gridColumns = columns.map((col) => {
    let disableColumnMenu = enableCustomFiltering ? false : true;
    if (col.mapping === "id") {
      disableColumnMenu = true;
    }
    const newCol = {
      field: col.mapping,
      headerName: col.name,
      sortable: col.sortable || false,
      disableColumnMenu,
      headerClassName: 'super-app-theme--header',
      ...col
    }
    if (!newCol.width) {
      newCol.flex = 1;
    }
    return newCol;
  });
  const classes = useStyles( themeColors || ( enabledarktheme ? darktheme : colortheme) );
  return (

    <div style={{ height: props?.customHeight || 400, width: '100%' }} className={classes.root}>
      <DataGrid
        rows={[...rows]}
        clearFilterSortState={props?.clearFilterSortState}
        columns={gridColumns}
        filterModel={{
          items: [{ columnField: 'id', operatorValue: 'contains', value: '' }],
        }}
        hideFooterPagination
        selectionModel={props?.selectionModel}
        disableSelectionOnClick={disableSelectionOnClick}
        showColumnRightBorder
        onSelectionModelChange={props?.onSelectionModelChange}
        showCellRightBorder
        hideFooter
        headerHeight={40}
        rowHeight={props?.customRowHeight || 35}
        toolbarConfig={props?.toolbarConfig}
        onRowEditCommit={props?.onRowEditCommit}
        isCellEditable={props?.isCellEditable}
        onCellClick={props?.onCellClick}
        editRowsModel={props?.editRowsModel}
        onEditRowsModelChange={props?.onEditRowsModelChange}
        // Handling the Remote Filtering Option
        remoteFiltering={props?.remoteFiltering}
        applyRemoteFiltering={props?.applyRemoteFiltering}
        defaultFilters={props?.defaultFilters}
        defaultFilterOrder={props?.defaultFilterOrder}
        getRowClassName={props?.getRowClassName}
        getCellClassName={props?.getCellClassName}
        autoHeight={props?.autoHeight}
      />
    </div>
  )
}
export default Grid;
