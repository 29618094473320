import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { copyToClipboard } from '../../../../common/commons';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
    download: {
        position: 'absolute',
        right: '30px',
        marginTop: '5px',
        width: '27px',
        height: '27px'
    },
    JsonContentWrapper: {
        marginLeft: '15px',
        marginRight: '15px',
        background: '#f8f9fa',
        position: 'relative'
    },
    copyToClipboard: {
        position: 'absolute',
        right: '0px',
        marginTop: "5px",
        width: '27px',
        height: '27px'
    }
}));

const ResponseJsonContent = ({ responseData, tabInfo,
    customHeaderStyles = {
        boxShadow: 'inset 0rem 0rem 0.25rem rgb(0 0 0 / 8%)',
        overflowY: 'scroll',
    }
}) => {
    const classes = useStyles();
    const [showCopySuccess, setShowCopySuccess] = useState(false);

    const downloadJSON = () => {
        const link = document.createElement('a');
        const file = new Blob([JSON.stringify(responseData.response_json, null, 2)], { type: 'application/json' })
        link.href = URL.createObjectURL(file);
        link.setAttribute('download', `${tabInfo.dsarRequestId}_${responseData.app_name}_Response.json`);
        document.body.appendChild(link);
        link.click();
        link.remove()
    };
    const copyClip = () => {
        copyToClipboard(responseData.response_json);
        setShowCopySuccess(true);
        setTimeout(() => {
            setShowCopySuccess(false);
        }, 1500);
    }
    return (<Box className={classes.JsonContentWrapper} height={`calc(100% - 150px)`} {...customHeaderStyles}>
        <Tooltip title={"download"}>
            <IconButton className={classes.download} 
            disabled={!responseData.response_json}
            onClick={() => { downloadJSON() }} aria-label="download">
                <GetAppIcon />
            </IconButton>
        </Tooltip>
        <Tooltip open={showCopySuccess} title="Copied!" position="bottom" >
            <IconButton className={classes.copyToClipboard} 
            disabled={!responseData.response_json}
            onClick={() => {
                copyClip()
            }} aria-label="copy to Clipboard">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" fill="currentColor"/>
                </svg>
            </IconButton>
        </Tooltip>
        <pre>{JSON.stringify(responseData?.response_json || {}, null, 2)}</pre>
    </Box>)
}
export default React.memo(ResponseJsonContent);