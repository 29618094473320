import { APPIDS } from "../../../../common/constants";

export const pageMapping = {
  viewrequest: {
    url: "request",
    app_id: null,
    payload_type : "cps",
  },
  viewupstreamrequest: {
    url: "request",
    app_id: null,
    downloadFileName: (dsrId) => {
      return `${dsrId}_Original.json`;
    }
  },
  viewsubarurequest: {
    url: "request",
    app_id: APPIDS.APP_ID_SUBARU,
    downloadFileName: (dsrId) => {
      return `${dsrId}_Subaru.json`;
    }
  },
  viewwovenrequest: {
    url: "request",
    app_id: APPIDS.APP_ID_WOVENCORE,
    downloadFileName: (dsrId) => {
      return `${dsrId}_Woven.json`;
    }
  },
  viewnonpijson: {
    url: "request",
    app_id: null,
    payload_type : "non_pi",
    downloadFileName: (dsrId) => {
      return `${dsrId}_NonPI.json`;
    }
  }
};