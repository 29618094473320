import { Typography, makeStyles, Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { v2UIRoutePath } from '../../../../common/commons';
import { Tab, Tabs } from '../reconcilationreport/MuiStyled';
import RequestsReportGrid from './RequestsReportGrid';
import axios from '../../../../common/AxiosConfig';
import { AppContext } from '../../../../../RoutesWithAuth';
import { useContext } from 'react';


const useStyles = makeStyles(() => ({
    root: {
        '& .ashyperlink': {
            color: "#007bff",
            cursor: "pointer"
        }
    }

}));
const requestTabs = ["", "reviewrequests"];
const DuplicateRequestColumns = [{
    name: "#",
    mapping: "id",
    width: 40
}, {
    name: 'Request ID',
    mapping: "dsar_id",
    renderCell: (params) => {
        return (<span className='ashyperlink'>
            {params.row["dsar_id"]}
        </span>)
    }
}, {
    name: "Request Type",
    mapping: "ticket_type",
    width: 200
}, {
    name: "Requestor",
    mapping: "requestor",
    width: 160
}, {
    name: "Request Status",
    mapping: "status",
    width: 160
}, {
    name: "Created Date",
    mapping: "created_date",
    width: 200,
    renderCell: (params) => (
        <Tooltip title={params.row["createdDateandTimeLocal"]}>
            <span>
                {params.row["createdDateandTimeUTC"]}
            </span>
        </Tooltip>
    ),
}];
const ReviewRequestsColumns = [
    ...DuplicateRequestColumns.slice(0, 3),
    {
        name: "Workflow",
        mapping: "workflow",
        width: 180,
    },
    ...DuplicateRequestColumns.slice(3)
]
const ManualActionsRequestReport = ({ tabActiveParam }) => {
    const classes = useStyles();
    // State to handle the Main Tab Information
    const [activeTab, setActiveTab] = useState(0);
    const context = useContext(AppContext)
    const authToken = context.authToken.get

    const history = useHistory();
    const location = useLocation();


    useEffect(() => {
        // Activating the Tab based on the tab param in the route url
        let activeTabIndex = requestTabs.indexOf(tabActiveParam);
        // activating the first tab when the tab name does not match the list
        if (activeTabIndex < 0) {
            activeTabIndex = 0;
        }
        setActiveTab(activeTabIndex);
    }, [tabActiveParam])

    const downloadJSON = ({dsar_id}) => {
        axios.get(`/queued_requests/${dsar_id}/json`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {

            const downloadLink = document.createElement("a");
            const blob = new Blob([JSON.stringify(res.data, null, 4)], { type: 'application/json' })
            const url = URL.createObjectURL(blob)
            downloadLink.href = url
            downloadLink.download = `${dsar_id}.json`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        })
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={'tabHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);
                    let subTab = "";
                    // Maintaining the active Tab State.
                    if (activeTab === 1) {
                        subTab = "reviewrequests"
                    }
                    return {
                        pathname: v2UIRoutePath + "reports/manualactionrequests/" + subTab,
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    MANUAL ACTIONS REQUESTS
                </Link>
            </Typography>
            <Tabs
                variant="scrollable"
                aria-label="tabs"
                value={activeTab}
                onChange={(_event, newValue) => {
                    setActiveTab(newValue);
                    // redirecting to the selected tab url so that refresh will retain in same page
                    history.push(`${v2UIRoutePath}reports/manualactionrequests/${requestTabs[newValue]}`)

                }}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Duplicate Requests" />
                <Tab label="Review Requests" />
            </Tabs>
            {activeTab === 0 && (<RequestsReportGrid
                fromredirection={location?.state?.fromredirection}
                api={"/reports/duplicate-requests"}
                columns={DuplicateRequestColumns}
                downloadEnabled={false}
                onCellClick={(cellparams) => {
                    history.push(v2UIRoutePath + "requestdetails/" + cellparams.row.dsar_id);

                }}
            />)}

            {activeTab === 1 && (<RequestsReportGrid
                fromredirection={location?.state?.fromredirection}
                api={"/reports/review-report"}
                columns={ReviewRequestsColumns}
                downloadEnabled={true}
                onCellClick={(cellparams) => {
                    if(cellparams.row.workflow === "Response Consolidation"){
                        history.push(v2UIRoutePath + "requestdetails/" + cellparams.row.dsar_id);
                    } else {
                        downloadJSON(cellparams.row)
                    }
                }}
            />)}

        </div>
    )
};

export default React.memo(ManualActionsRequestReport);
