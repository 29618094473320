import { Tooltip } from '@material-ui/core';
import React from 'react';
import { RequestorMapping } from '../../../common/commons';

const AllDropDownOptions = {
    "ticket_type" : ['Right-to-Correct', 'Right-to-Delete', 'Right-to-Know-Detailed', 'Right-to-OptOut'],
    "requestor" : [RequestorMapping.B2B, RequestorMapping.CUSTOMER, RequestorMapping.EMPLOYEE, RequestorMapping.TCICUSTOMER],
    "status" : ['Pre Open', 'Open', 'Review', 'Closed', 'Uploaded', 'Upload Failed', 'Cancelled'],
}

export const getHomePageColumns = (app_Info,advancedFilter) => {
    const getValueOptions = (type)=>{
        let options = AllDropDownOptions[type];
        if(type === "app"){
            options = app_Info?.map(({ display }) => display);
        }
        if(advancedFilter[type] && !advancedFilter[type]?.includes("All")){
            options = advancedFilter[type];
        }
        return options;
    }
    return [{
        name: "#",
        mapping: "id",
        width: 40
    }, {
        name: "Request ID",
        mapping: "requestId",
        renderCell: (params) => (
            <a href="void(0)" rel="noopener noreferrer" onClick={(event)=>{
                event.preventDefault();
            }}>
                {params.row["requestId"]}
            </a>
        )
    },
    {
        name: "Request Type",
        mapping: "ticket_type",
        width: 155,
        sortable: true,
        type: 'singleSelect',
        valueOptions: getValueOptions("ticket_type"),
        customSingleSelectFilter: true,
    },
    {
        name: "Requestor",
        mapping: "requestor",
        width: 136,
        sortable: true,
        type: 'singleSelect',
        valueOptions: getValueOptions("requestor"),
        customSingleSelectFilter: true,
    },
    {
        name: "Request Status",
        mapping: "status",
        width: 165,
        sortable: true,
        type: 'singleSelect',
        valueOptions: getValueOptions("status"),
        customSingleSelectFilter: true,
    }, {
        name: "Create Date Time",
        mapping: "created_date",
        type: "date",
        sortable: true,
        renderCell: (params) => (
            <Tooltip title={params.row["createdDateandTimeLocal"]}>
                <span>
                    {params.row["createdDateandTimeUTC"]}
                </span>
            </Tooltip>
        ),
        width: 180
    }, {
        name: "DownStream Apps",
        mapping: "downstreamApps",
        sortable: true,
        valueOptions: getValueOptions("app"),
        displayTootip: ["App Name List"],
        customSingleSelectFilter: true,
        width: 190
    }, {
        name: "VIN(s) #",
        mapping: "noOfVins",
        width: 125,
        disableColumnMenu: true,
        sortable: true,
        type: "number",
        align: 'left',
        headerAlign: 'left',
    }, {
        name: "GUID(s) #",
        mapping: "noOfGUIDS",
        width: 134,
        disableColumnMenu: true,
        sortable: true,
        type: "number",
        align: 'left',
        headerAlign: 'left',
    }, {
        name: "Cust ID(s) #",
        mapping: "noOfCustIds",
        width: 145,
        disableColumnMenu: true,
        sortable: true,
        type: "number",
        align: 'left',
        headerAlign: 'left',
    }]
}


export const getAdvancedSearchFields = (apps) => [{
    type: 'text',
    name: 'id',
    label: 'DSAR ID',
    enableTrim: true,
    handleEnterToSubmit : true
}, {
    type: 'text',
    name: 'response_contains',
    label: 'Response Contains',
    handleEnterToSubmit: true
}, {
    type: 'text',
    name: 'request_contains',
    label: 'Request Contains',
    handleEnterToSubmit: true
}, {
    type: 'multiselect',
    name: 'region',
    label: 'Region',
    isMulti: true,
    options: ['All', 'US', 'Canada'],
    defaultOption: 'All'
}, {
    type: 'multiselect',
    name: 'ticket_type',
    label: 'Request Type',
    isMulti: true,
    options: ['All', 'Right-to-Correct', 'Right-to-Delete', 'Right-to-Know-Detailed', 'Right-to-OptOut'],
    defaultOption: 'All'
}, {
    type: 'multiselect',
    name: 'status',
    label: 'Request Status',
    isMulti: true,
    options: ['All', 'Pre Open', 'Open', 'Review', 'Closed', 'Uploaded', 'Upload Failed', 'Cancelled'],
    defaultOption: 'All'
}, {
    type: 'multiselect',
    name: 'requestor',
    label: 'Requestor',
    isMulti: true,
    options: ['All', RequestorMapping.B2B, RequestorMapping.CUSTOMER, RequestorMapping.EMPLOYEE, RequestorMapping.TCICUSTOMER],
    defaultOption: 'All'
}, {
    type: 'multiselect',
    name: 'app',
    label: 'Downstream Apps',
    isMulti: true,
    options: ['All', ...apps?.map(({ display }) => display)],
    defaultOption: 'All'
}, {
    type: 'multiselect',
    name: 'cali_customer',
    label: 'California Customer',
    options: ['All', 'Yes', 'No'],
    defaultOption: 'All'
}, {
    type: 'date',
    name: 'from_date',
    label: 'From Date'
}, {
    type: 'date',
    name: 'to_date',
    label: 'To Date'
}]