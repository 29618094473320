import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({

    dialog : {
        '& .deleteDialogKey' :{
            border: '1px solid #95a3bd',
            borderRadius: '8px',
            margin: '10px',
            height: '70px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
        },
        '& .MuiDialog-paperScrollPaper':{
            borderRadius : '0px',
        },
        '& .MuiDialogTitle-root':{
            padding: '10px',
            background: 'rgb(127, 158, 215)',
            color: 'white',
            '& h2' :{
                fontSize: '1rem'
            }
        },
        '& .MuiTypography-colorTextSecondary':{
            color : 'black',
        },
        '& .MuiDialogActions-root':{
            justifyContent: 'space-evenly',
            '& .MuiButtonBase-root':{
                background: 'rgb(127, 158, 215)',
                color: 'white',
                textTransform : 'none'
            }
        }
    }
}));

const DeleteDialog = (props)=>{

    const {
        title,
        openDeleteDialog,
        handleDeleteDialogClose,
        handleDeleteDialogSubmit
    } = props;
    const classes = useStyles()

    return (
        <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose} className={classes.dialog}>
                <DialogTitle >{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteDialogSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default DeleteDialog;